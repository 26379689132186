export const SESSION_HANDOUTS_FILE_RESOURCE_FRAGMENT = `
  fragment sessionHandoutsFileResourceFragment on Session {
    uid
    name
    handouts {
      uid
      name
      schemaCode
      handoutFileResource {
        path
        fileType
      }
    }
  }
`;
